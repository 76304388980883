import React, { useEffect } from "react"
import Section from "./section"

import '../../node_modules/react-modal-video/scss/modal-video.scss'
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import db from '../libs/firebase'


export default () => {
  const [voting, setVoting] = useQueryParam("voting", NumberParam);
  const [option, setOption] = useQueryParam("option", StringParam);
  const [email, setEmail] = useQueryParam("email", StringParam);


  useEffect(() => {
    try {
      db.collection('voting').add({
        email: email.toLowerCase(),
        option,
        voting,
        userAgent: navigator.userAgent 
      })  
    } catch (error) {
      console.error(error)
    }
  }
)

  return (
    <Section>
      <div className="py-16 bg-gray-50 overflow-hidden sm:py-24" id="features">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 sm:px-8 sm:max-w-screen-xl">

          <div className="relative">
            <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-darkpurple sm:text-4xl sm:leading-10">
              Thank you for your vote!
            </h3>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
              🙏🏼
            </p>
          </div> 

        </div>
      </div>
    </Section>
  )
}
